import { getManagerList, getManagerTableData, deleteShopItem, getExcel, insertExcel, postShopItemsIdOnline, postShopItemsIdOffline, postShopItemStockTaking } from '@/services/product'
// import { getHistory } from '@/services/resource'
import searchForm from '@/components/searchForm'
import importButton from '@/components/importButton'
import allButton from '@/components/allButton'
// import dropListCollect from '@/components/dropListCollect'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
import jsonData from './jsonData.js'
import _ from 'lodash'
import { mapState } from 'vuex'
import loading from '@/utils/loading.json'
export default {
  name: 'managerProduct',
  components: {
    searchForm,
    // alterForm,
    createTable,
    // dropListCollect,
    importButton,
    allButton
    // topTabBtn
  },
  mixins: [mixin],
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      insertExcel: insertExcel
    }
  },
  computed: {
    ...mapState({
      phone: state => state.app.phone
    })
  },
  created () {
    // console.log(this.$route)
    this.queryData(getManagerList)
  },
  activated () {
    if (this.isActive) this.queryData(getManagerList)
    this.isActive = true
  },
  mounted () {
  },
  watch: {
    $route (to, from) {
      if (from.name === 'managerProductEdit') {
        this.$nextTick(() => {
          this.search()
          this.$refs.createTable.$refs.table.doLayout()
        })
      }
    }
  },
  methods: {
    async forwardToPage (data, name) {
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // tab切换
    tabChange (val) {
      const index = this.dropDataItem.findIndex(item => item.value === this.tabData[val].value)
      const data = _.cloneDeep(this.deepDropItem)
      if (index !== -1) {
        data.splice(index, 1)
        this.dropDataItem = data
      } else {
        this.dropDataItem = data
      }
      // data.splice()
    },
    // 排序
    handleSort (val) {
      this.$refs.searchForm.onSubmit(val)
    },
    // 文件导入
    handleImport () {
    },
    // 导出数据接口
    handleExport () {
      // this.$refs.searchForm.exportExcel(getExcel)
      this.$refs.searchForm.commonOperate(getExcel, (data) => {
        this.$router.push({
          name: 'goodsManagerProductDownloadCenter',
          params: { data }
        })
      }, this)
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getManagerTableData(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'goodsManagerProductAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'goodsManagerProductEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteShopItem)
      }
    },
    // 切换伸缩框
    handleChange (val) {
      // console.log(val)
    },
    // 删除数据
    // deleteClick (data) {
    //   // mock操作
    //   this.$confirm('是否删除该数据?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.state.app.loading = this.$loading(loading)
    //     deleteShopItem(data.id.value).then(res => {
    //       this.$store.state.app.loading.close()
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       })
    //       this.queryData(getManagerList)
    //     }).catch(() => this.$store.state.app.loading.close())
    //   }).catch(() => {
    //   })
    // },
    // 数据的选择
    selectionChange (val) {
      this.selectionData = val
    },
    handleStockTaking () {
      this.$refs.searchForm.commonOperate(postShopItemStockTaking, (data) => {
      }, this)
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      if (jsonItemData.prop === 'online') {
        if (rowData[jsonItemData.prop].value) {
          _this.setGlobalLoading(true)
          postShopItemsIdOnline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        } else {
          _this.setGlobalLoading(true)
          postShopItemsIdOffline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        }
      }
    }
  }
}
