import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'image',
    label: '',
    height: '100px',
    width: '160',
    img: true
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'supplier',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '220'
  }, {
    prop: 'shop_item_stocks_information',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '80'
  }],
  dialogBasicData: [{
    collname: i18n.t('form.product.basicMsg'),
    alterFormData: [{
      span: 4,
      label: '',
      name: i18n.t('form.product.online'),
      prop: 'online',
      type: 'position',
      value: 1
    }, {
      span: 4,
      label: '',
      name: i18n.t('form.product.recommend'),
      prop: 'recommend',
      type: 'position',
      value: 0
    }, {
      span: 4,
      label: '',
      name: i18n.t('form.product.searchable'),
      prop: 'searchable',
      type: 'position',
      value: 0
    }, {
      span: 4,
      label: '',
      name: i18n.t('form.product.freeDelivery'),
      prop: 'free_delivery',
      type: 'position',
      value: 0
    }, {
      span: 24,
      label: i18n.t('form.product.onlineTime'),
      datetime: true,
      type: 'date',
      prop: 'online_time'
    }, {
      span: 8,
      label: i18n.t('form.product.shop'),
      type: 'select',
      prop: 'shop_id',
      options: [],
      rule: [
        { required: true, message: '请选择店铺', trigger: ['blur', 'change'] }
      ],
      change: true
    }, {
      span: 8,
      label: i18n.t('form.product.supplier'),
      type: 'select',
      prop: 'supplier_id',
      options: []
    }, {
      span: 8,
      label: i18n.t('form.product.shopType'),
      type: 'select',
      prop: 'item_type',
      options: [],
      rule: [
        { required: true, message: '请选择商品类型', trigger: ['blur', 'change'] }
      ]
    }, {
      span: 24,
      label: i18n.t('form.product.shopCategories'),
      group: [],
      marginVisible: false,
      name: 'category_ids',
      Object: 'id',
      prop: 'shop_categories',
      string: true,
      type: 'checkGroup'
    }, {
      span: 24,
      label: i18n.t('form.product.shopSubCategories'),
      prop: 'shop_sub_categories',
      name: 'shop_sub_category_ids',
      Object: 'id',
      string: true,
      marginVisible: false,
      type: 'checkGroup',
      group: []
    }, {
      span: 12,
      label: i18n.t('form.product.cnName'),
      type: 'input',
      prop: 'name',
      rule: [
        { required: true, message: '请输入中文名称', trigger: 'blur' }
      ]
    }, {
      span: 12,
      label: i18n.t('form.product.enName'),
      type: 'input',
      prop: 'name_en'
    }, {
      span: 24,
      label: i18n.t('form.product.maximumLimitPerUser'),
      type: 'number',
      prop: 'maximum_limit_per_user'
    }, {
      span: 24,
      label: i18n.t('form.product.initSale'),
      type: 'number',
      prop: 'init_sale',
      rule: [
        { required: true, message: '初始销售不能为空' },
        { type: 'number', message: '初始销售为数字值' }
      ]
    }, {
      span: 12,
      label: i18n.t('form.product.shortDescriptionCn'),
      type: 'input',
      prop: 'short_description'
    }, {
      span: 12,
      label: i18n.t('form.product.shortDescriptionEn'),
      type: 'input',
      prop: 'short_description_en'
    }, {
      span: 24,
      label: i18n.t('form.product.descriptionCn'),
      type: 'editor',
      prop: 'description'
    }, {
      span: 24,
      label: i18n.t('form.product.descriptionEn'),
      type: 'editor',
      prop: 'description_en'
    }, {
      span: 24,
      label: i18n.t('form.product.globalLabels'),
      group: [{
        name: '春节特惠(CNY Special)',
        prop: '春节特惠'
      }, {
        name: '热销榜单(Best Sales)',
        prop: '热销榜单'
      }, {
        name: '冷冻冷藏(Frozen Food)',
        prop: '冷冻冷藏'
      }],
      marginVisible: false,
      prop: 'global_labels',
      string: true,
      type: 'checkGroup'
    }]
  }, {
    collname: i18n.t('form.product.featuredTags'),
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: i18n.t('form.product.createFeaturedTags'),
      prop: 'labels',
      operate: 'true',
      array: [{
        span: 12,
        label: i18n.t('form.product.cnName'),
        type: 'input',
        prop: 'name'
      }, {
        span: 12,
        label: i18n.t('form.product.enName'),
        type: 'input',
        prop: 'name_en'
      }]
    }]
  }, {
    collname: i18n.t('form.product.image'),
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: i18n.t('form.product.createImage'),
      prop: 'images',
      operate: 'true',
      limit: true,
      array: [{
        span: 24,
        label: i18n.t('form.product.imageFiles'),
        type: 'avatar',
        prop: 'image_url',
        file: 'img',
        limit: 10
      }]
    }]
  }, {
    collname: i18n.t('form.product.buyNotices'),
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: i18n.t('form.product.addNotices'),
      prop: 'notices',
      operate: 'true',
      array: [{
        span: 24,
        label: 'Display order',
        type: 'number',
        prop: 'display_order',
        value: 1
      }, {
        span: 12,
        label: i18n.t('form.product.titleCn'),
        type: 'input',
        prop: 'title'
      }, {
        span: 12,
        label: i18n.t('form.product.titleEn'),
        type: 'input',
        prop: 'title_en'
      }, {
        span: 12,
        label: i18n.t('form.product.contentCn'),
        type: 'input',
        prop: 'detail'
      }, {
        span: 12,
        label: i18n.t('form.product.contentEn'),
        type: 'input',
        prop: 'detail_en'
      }]
    }]
  }, {
    // 商品型号
    collname: i18n.t('form.product.shopStock'),
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: i18n.t('form.product.createStock'),
      prop: 'shop_item_stocks',
      operate: 'true',
      other: true,
      array: [{
        span: 24,
        label: '',
        name: i18n.t('form.product.online'),
        prop: 'online',
        type: 'position',
        value: 1
      }, {
        span: 12,
        label: i18n.t('form.product.cnName'),
        type: 'input',
        prop: 'name'
      }, {
        span: 12,
        label: i18n.t('form.product.enName'),
        type: 'input',
        prop: 'name_en'
      }, {
        span: 6,
        label: 'SKU ID',
        type: 'input',
        prop: 'sku_id'
      }, {
        span: 6,
        label: i18n.t('form.product.priceUnit'),
        type: 'input',
        prop: 'price_f',
        value: '0.0'
      }, {
        span: 6,
        label: i18n.t('form.product.costPriceUnit'),
        type: 'input',
        prop: 'cost_price_f',
        value: '0.0'
      }, {
        span: 6,
        label: i18n.t('form.product.linePriceUnit'),
        type: 'input',
        prop: 'line_price_f',
        value: '0.0'
      }, {
        span: 24,
        label: i18n.t('form.product.image'),
        type: 'avatar',
        prop: 'image_url',
        file: 'img',
        limit: 1
      }, {
        span: 6,
        label: i18n.t('form.product.slaingStock'),
        type: 'number',
        prop: 'saling_stock',
        rule: [
          { required: true, message: '初始销售不能为空' },
          { type: 'number', message: '初始销售为数字值' }
        ]
      }, {
        span: 6,
        label: i18n.t('form.product.warningStock'),
        type: 'number',
        prop: 'warning_stock',
        rule: [
          { required: true, message: '初始销售不能为空' },
          { type: 'number', message: '初始销售为数字值' }
        ]
      }, {
        span: 6,
        label: i18n.t('form.product.lockingStock'),
        type: 'number',
        prop: 'locking_stock',
        rule: [
          { required: true, message: '初始销售不能为空' },
          { type: 'number', message: '初始销售为数字值' }
        ]
      }]
    }]
  }, {
    collname: i18n.t('form.product.preferences'),
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: i18n.t('form.product.createPreferences'),
      prop: 'shop_item_preferences',
      operate: 'true',
      array: [{
        span: 12,
        label: i18n.t('form.product.title'),
        type: 'input',
        prop: 'name'
      }, {
        span: 12,
        label: i18n.t('form.product.displayOrder'),
        type: 'number',
        prop: 'display_order',
        value: 100000
      }, {
        span: 12,
        label: i18n.t('form.product.minCount'),
        type: 'number',
        prop: 'min_count'
      }, {
        span: 12,
        label: i18n.t('form.product.maxCount'),
        type: 'number',
        prop: 'max_count'
      }, {
        span: 24,
        label: i18n.t('form.product.preferenceOptions'),
        type: 'array',
        prop: 'preference_options',
        btnName: i18n.t('form.product.createPreferOptions'),
        operate: 'true',
        array: [{
          span: 12,
          label: i18n.t('form.product.name'),
          type: 'input',
          prop: 'name'
        }, {
          span: 12,
          label: i18n.t('form.product.price'),
          type: 'input',
          prop: 'price_f'
        }]
      }]
    }]
  }],
  copyDataItem: [{
    title: '基本信息',
    type: 'form',
    index: 0,
    data: [{
      label: '中文名称',
      prop: 'name'
    }, {
      label: '店铺',
      prop: 'shop',
      object: 'name'
    }, {
      label: '商品类型',
      prop: 'item_type'
    }, {
      label: '排序优先级',
      prop: 'item_type1'
    }, {
      label: '单位',
      prop: 'item_type2'
    }, {
      label: '上架',
      prop: 'online'
    }, {
      label: '描述(中文)',
      prop: 'description',
      text: true
    }, {
      label: '价格',
      prop: 'item_type3'
    }, {
      label: '最低价',
      prop: 'item_type4'
    }, {
      label: '取货方式',
      prop: 'item_type5'
    }, {
      label: '送货上门酒店',
      prop: 'item_type6'
    }, {
      label: '特色标签',
      prop: 'labels'
    }, {
      label: '须知添加',
      prop: 'item_type7'
    }, {
      label: '购买须知',
      prop: 'notices'
    }]
  }, {
    title: '商品型号',
    type: 'table',
    prop: 'shop_item_stocks',
    data: [{
      prop: 'name',
      label: '中文名称',
      width: '70',
      span: true
    }, {
      prop: 'image_url',
      label: '图片',
      width: '60',
      height: '40px',
      img: true
    }, {
      prop: 'price',
      label: '价格',
      width: '40',
      span: true
    }, {
      prop: 'cost_price',
      label: '成本价',
      span: true,
      width: '40'
    }, {
      prop: 'basic_information',
      label: '优惠价',
      span: true,
      width: '40'
    }, {
      prop: 'shop_item_stocks_information',
      label: '已售',
      span: true,
      width: '40'
    }, {
      prop: 'saling_stock',
      label: '可销售库存',
      span: true,
      width: '40'
    }, {
      prop: 'warning_stock',
      label: '预警库存',
      span: true,
      width: '40'
    }, {
      prop: 'locking_stock',
      label: '锁定库存',
      span: true,
      width: '40'
    }, {
      prop: 'online',
      label: '上架',
      span: true,
      width: '40'
    }]
  }, {
    title: '喜好',
    type: 'table',
    prop: 'shop_item_preferences',
    data: [{
      prop: 'name',
      label: '标题',
      width: '70',
      span: true
    }, {
      prop: 'display_order',
      label: '显示顺序',
      width: '60',
      span: true
    }, {
      prop: 'min_count',
      label: '最小选择数量',
      width: '40',
      span: true
    }, {
      prop: 'max_count',
      label: '最大选择数量',
      span: true,
      width: '40'
    }, {
      prop: 'preference_options',
      label: '选项',
      array: true,
      object: 'name',
      width: '40'
    }]
  }],
  productBasicViewItem: [{
    prop: 'name',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'item_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'online',
    label: '',
    back: true,
    Object: 'value'
  }, {
    prop: 'recommend',
    label: '',
    Object: 'value'
  }, {
    prop: 'searchable',
    label: '',
    Object: 'value'
  }, {
    prop: 'free_delivery',
    label: '',
    Object: 'value'
  }, {
    prop: 'online_time',
    label: '',
    Object: 'value'
  }, {
    prop: 'init_sale',
    label: '',
    Object: 'value'
  }, {
    prop: 'maximum_limit_per_user',
    label: '',
    Object: 'value'
  }, {
    prop: 'short_description',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'short_description_en',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'description',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'description_en',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'global_labels',
    label: '',
    span: 24,
    Object: 'value'
  }, {
    prop: 'labels',
    label: '',
    span: 24,
    Object: 'value'
  }],
  imagesViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '20'
  }, {
    prop: 'image',
    label: '',
    img: true,
    autoWidth: false,
    width: '120'
  }],
  shopItemStocksViewItem: [{
    prop: 'name',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'image',
    label: '',
    Object: 'value',
    img: true,
    autoWidth: false,
    width: '60'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'cost_price',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'saling_stock',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }, {
    prop: 'sold',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '60'
  }],
  shopSubCategoriesViewItem: [{
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'image',
    label: '',
    // Object: 'value',
    img: true,
    autoWidth: false,
    width: '60'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value'
  }, {
    prop: 'rank',
    label: '',
    Object: 'value'
  }]
}
